




















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Freq } from '@tada-team/tdproto-ts'

@Component({
  components: {
    IconRepeat2: () => import('@tada/icons/dist/IconRepeat2.vue'),
  },
})
export default class MeetingDialogHead extends Vue {
  @Prop({
    type: Boolean,
  }) isEditDialog!: boolean

  @Prop({
    type: Object,
  }) freq?: Freq

  private get title () {
    return this.isEditDialog
      ? this.$t('meetings.meetingDialog.title').toString()
      : this.$t('meetings.meetingDialog.titleNew').toString()
  }
}
